import {
  Box,
  Center,
  Container,
  Heading,
  Spinner,
  Text,
} from '@chakra-ui/react'

import Head from 'next/head'

const CheckoutIsLoading = () => {
  return (
    <Container h="100vh">
      <Head>
        {/* <link rel="icon" href="/favicon.ico" /> */}
        <title>Checkout</title>
      </Head>
      <Center h="full" w="full">
        <Box textAlign="center">
          <Spinner color="green.300" size="xl" />
          <Heading color="green.300">Aguarde...</Heading>
          <Text fontSize="lg" color="black">
            Estamos processando seu carrinho.
          </Text>
          <Text fontSize="lg" color="black">
            Não feche a tela.
          </Text>
        </Box>
      </Center>
    </Container>
  )
}

export default CheckoutIsLoading
