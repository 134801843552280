import store from "@app/store";
import {
  retrieveData,
  setOriginalToken,
  setStoreToken,
  setIp,
} from "@features/app/appSlice";
import type {
  GetServerSideProps,
  InferGetServerSidePropsType,
  NextPage,
} from "next";
import { useRouter } from "next/router";
import { useEffect } from "react";

import CheckoutIsLoading from "@components/CheckoutIsLoading";
import { Box, Center, Container, Heading, Text } from "@chakra-ui/react";
const IndexPage: NextPage = ({
  storeToken,
  ip,
  error,
}: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  const router = useRouter();

  useEffect(() => {
    if (storeToken) {
      store.dispatch(setStoreToken(storeToken));
      store.dispatch(setOriginalToken(storeToken));
      store.dispatch(retrieveData({ storeToken, type: "default" }));
      store.dispatch(setIp(ip));
      router.push("/checkout");
    } else {
    }
  }, []);

  if (!storeToken) {
    return (
      <Container h="100vh">
        <Center h="full" w="full">
          <Box textAlign="center">
            <Box>
              <Heading color="green.300">Ops...</Heading>
            </Box>
            <Text fontSize="lg" color="black">
              Não foi encontrado nenhum produto, certifique-se de que o link
              está correto.
            </Text>
          </Box>
        </Center>
      </Container>
    );
  }

  return (
    <>
      <CheckoutIsLoading />
    </>
  );
};

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const { storeToken, url } = ctx.query as { storeToken: string; url: string };
  const ip = ctx.req.headers["x-real-ip"] || ctx.req.socket.remoteAddress;

  try {
    return {
      props: {
        storeToken: storeToken ?? null,
        url: url ?? null,
        error: null,
        ip,
      },
    };
  } catch (error) {
    return { props: { error: JSON.stringify(error) } };
  }
};

export default IndexPage;
